import React, { Fragment } from "react";
import { ThemeProvider } from "styled-components";
import Layout from "../containers/Home/Layout";
import { interiorTheme } from "common/theme/interior";
import "common/assets/css/main-page.css";

import Seo from "components/seo";

import TitleBar2 from "common/components/Titlebar2";
import SingleServicePage from "../containers/Home/pages.style";
import ServiceSectionTwo from "../containers/Sections/ServiceSectionTwo";
import ServiceSectionThree from "../containers/Sections/ServiceSectionThree";
import ServiceSectionOne from "../containers/Sections/ServiceSectionOne";
import ServiceSectionFour from "../containers/Sections/ServiceSectionFour";

const HairWeavingForMen = () => {

  const seoData = {
    title: 'Hair Weaving For Men in Delhi - Radiance Hair Studio',
    description: 'Explore the world of hair weaving in Delhi with Radiance Hair Studio. Discover an exciting method for natural-looking results. Visit us today.',
    keywords: ['hair weaving for men in Delhi'],
  };

  const schemaData = {
    "@context": "https://schema.org",
    "@type": "Service",
    "@id": "Service",
    "alternateName": "Radiance Hair Studio",
    "areaServed": "New Delhi",
    "image": [
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/service-images/Hair-Weaving-for-Men's.jpg",
      "https://www.radiancehairstudio.com/hair-weaving-for-mens1.png",
      "https://www.radiancehairstudio.com/hair-weaving-for-mens1.png",
      "https://www.radiancehairstudio.com/hair-weaving-for-mens2.png",
      "https://www.radiancehairstudio.com/help-image-1.png"
    ],
    "logo": "https://www.radiancehairstudio.com/static/radiance-logo-721b0cd4def3d400c04ba899afd38810.png",
    "additionalType": "https://www.radiancehairstudio.com/hair-weaving-for-mens.php",
    "category": "https://www.radiancehairstudio.com/hair-weaving-for-mens.php",
    "description": "Explore the world of hair weaving in Delhi with Radiance Hair Studio. Discover an exciting method for natural-looking results. Visit us today.",
    "disambiguatingDescription": "hair weaving for men in Delhi",
    "serviceType": "Hair Weaving for Men's"
  }; 

  const sectionS1 = [
    {
      title: "Hair Patch for Men",
      img: "/hair-patch-for-men.png",
    },
    {
      title: "Human Hair Extension",
      img: "/7.png",
    },
    {
      title: "Hair Weaving",
      img: "/8.png",
    },
    {
      title: "Non-Surgical Hair Replacement",
      img: "/9.png  ",
    }
  ];

  const sectionS2 = [
    {
        img: "/service-images/Hair-Weaving-for-Men's.jpg",
        content: (
            <div>
                <h2>Hair Weaving in Delhi for Men's</h2>
                <p>Among the <strong><a href="/non-surgical-hair-replacement-for-men.php" style={{ color: "#373737"}}>Non-Surgical Hair replacement</a></strong> plans, three distinct methods are usually applied – glue, clip or tape. However, many think those are going to harm your natural hair, although there is not a single side-effect from the entire thing. For those, <strong >hair weaving in Delhi</strong> is the perfect plan to be applied. This is the method that is going to give you natural beauty and here the process is done by full weaving. </p>
                <h2>Radiance Strom</h2>
                <p>Radiance Hair Salon will be making a structure on your head using your natural hair only and then will be stitching the natural hair elongations with the structure itself. The process is a long drawn one, but the change you will find is perfectly natural.</p>
                <p>The specialty of this applied method is that – your extensions can never be identified. Even a closer look at your skull, will not be able to reveal the secret you are holding. Don’t get bothered of the tension of side effectcs from hair weaving. We would like to mention here that the products we use are completely harmless to your sensitive skin and our extensions are exclusively designed with 100% original human hair which will not only give you a natural look but also gives naturally a comfortable feel on your head.</p>
                <p>Radiance is having professionals that are perfect in handling this type of hairstyle. It’s a long drawn process and our professionals are competent enough to give you what you seek in style.</p>
                <p>Let’s have close look to the benefits of Hair Weaving.</p>
            </div>
        )
    }
];

const sectionS3 = [
    {
        smallImg: "/hair-weaving-for-mens1.png",
        largeImg: "/hair-weaving-for-mens2.png",
        content: (
            <div>
                <h2>Benefits of Hair Weaving</h2>
                <p></p>
                <ul>
                    <li>The first and the foremost thing that you must note here is the look you will get. It is going to give you a full natural appearance and that is exactly where the difference is <a href="/contact.php" style={{ color: "#373737"}}>created.</a> </li>
                    <li>The net-like structure that will be weaved on your head is the base of the entire beauty. Once that is created, natural hair extensions can easily be applied to that structure to give the appearance, you are seeking.  </li>
                    <li>The method that is applied here is perfect for men, however for women, natural hair is trimmed a lot for aiding the weaving process.  </li>
                    <li>The hairstyle that will be developed is not a temporary one. It is having a long life-span and moreover, you can apply shampoos or serums too. However, it will be better if you do those things, reaching the Salon. They will treat the weaved-structure identically. </li>
                </ul>
            </div>
        )
    }
];

const sectionS4 = [
    {
        title: "FAQ headings",
        description: "FAQS of Hair Weaving",
        description2: "",
        faq: [
            {
                title: "Will my natural hair be damaged?",
                description: "There is no external thing to be applied in this method – no glues, no tapes. Your natural hair will be weaved to give the new structure, based on which the elongation will remain. So, this is such a Non-Surgical Hair replacement process, where there is no risk at all."
            },
            {
                title: "Is there any side-effect?",
                description: "The process is not only an external one, but it is the process where even no chemicals will be used too. So, there remains the least chance of side-effect for your head as well as for your natural hair."
            },
            {
                title: "Can I change the style on daily basis?",
                description: "The elongations in hair weaving will be attached with the structure with clips or both sided tapes. Those are easy to be opened and re-applied. So, no hassle is there to open the extensions on daily basis and re-wear those on the next day."
            },
            {
                title: "Will I have to be careful from swimming or other things?",
                description: "The literal answer is no. The entire thing that you will be applying here is natural and hence there remains no possibility of any sort of damage."
            }
        ],
    }
];

  return (
    <ThemeProvider theme={interiorTheme}>
      <Fragment>
          <Seo {...seoData} schemaData={schemaData} />
        <Layout>
          <TitleBar2 title="Hair Weaving for Men's" banner="/hair-weaving-for-men-banner.png"/>
          <SingleServicePage>
            <ServiceSectionOne data={sectionS1} title="Hair Weaving for Men's" />
            <ServiceSectionTwo data={sectionS2} />
            <ServiceSectionThree data={sectionS3} />
            <ServiceSectionFour data={sectionS4} />
          </SingleServicePage>
        </Layout>
      </Fragment>
    </ThemeProvider>
  );
};
export default HairWeavingForMen;
